import React from 'react'

type ShellRedirectMfeType = {
  properties: {
    redirectUrl: string
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ properties }: ShellRedirectMfeType) {
  window.location.href = properties.redirectUrl

  return <></>
}
